import {
    Button, HStack, Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure, VStack
} from "@chakra-ui/react";
import React from "react";

export default function MobileSorryModal() {
    const modalProps = useDisclosure()

    React.useEffect(() => {
        modalProps.onOpen()
    }, [])

    return (
        <Modal {...modalProps}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader><b>모바일 환경?</b></ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    안녕하세요! 현재 모바일 이시거나 페이지를 세로로 길게 열고 게신것 같습니다.<br/>
                    이 경우 위치 조정 기능을 제대로 제공할 수 없으므로 자동으로 지정된 위치에 가장 큰 크기로 이미지가 전달됩니다.<br/>
                    위치가 자동이기 때문에 원하지 않는 위치에 이미지가 표시될 수도 있습니다.<br/>
                    위치를 세밀하게 조절하고 싶으시다면 화면을 가로로 전환해주시거나, 페이지를 가로로 길게 늘여주세요.
                </ModalBody>
                <ModalFooter>
                <VStack width={"auto"}>
                        <HStack>
                            <Button onClick={modalProps.onClose} colorScheme='blue' mr={3}>
                                알겠습니다.
                            </Button>
                        </HStack>
                    </VStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}