import {Box, useTheme} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import {ImageInfo} from "./ImageInfo";
import DraggableImage from "./DraggableImage";
import SendingUIBox from "./SendingUIBox";

export default function OverlayBox() {
    const [letterBox, setLetterBox] = useState(0)
    const [pillarBox, setPillarBox] = useState(0)

    const [viewWidth, setViewWidth] = useState(0)
    const [viewHeight, setViewHeight] = useState(0)

    function calculateBoxSizes(containerWidth: number, containerHeight: number, contentWidth: number, contentHeight: number) {
        // Calculate aspect ratios
        const containerAspectRatio = containerWidth / containerHeight;
        const contentAspectRatio = contentWidth / contentHeight;

        let pillarboxSize = 0;
        let letterboxSize = 0;

        if (contentAspectRatio > containerAspectRatio) {
            // Content is wider; pillarbox will be present
            const scaledHeight = containerWidth / contentAspectRatio;
            pillarboxSize = containerHeight - scaledHeight;
        } else if (contentAspectRatio < containerAspectRatio) {
            // Content is taller; letterbox will be present
            const scaledWidth = containerHeight * contentAspectRatio;
            letterboxSize = containerWidth - scaledWidth;
        }

        // Get the smallest box size
        const smallestBoxSize = Math.min(pillarboxSize, letterboxSize);

        return {
            pillarboxSize,
            letterboxSize,
            smallestBoxSize
        };
    }

    useEffect(() => {
        // 뷰포트 크기가 변경될 때 호출되는 핸들러 함수입니다.
        const handleResize = () => {
            const result = calculateBoxSizes(window.innerWidth - 353, window.innerHeight, 1920, 1080);
            // setPillarBox(Math.round(result.pillarboxSize / 2))
            // setLetterBox(Math.round(result.letterboxSize / 2))
            setPillarBox(result.pillarboxSize / 2)
            setLetterBox(result.letterboxSize / 2)
            console.log(`${window.innerWidth}, ${window.innerHeight}`)
            console.log(result)

            setViewWidth(window.innerWidth - 353 - (result.letterboxSize));
            setViewHeight(window.innerHeight - (result.pillarboxSize));
        };

        // 'resize' 이벤트에 핸들러를 추가합니다.
        window.addEventListener('resize', handleResize);
        handleResize()

        // 컴포넌트가 unmount 될 때 이벤트 리스너를 제거합니다.
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const theme = useTheme();
    const [extension, setExtension] = useState("standard")

    const [previewUrl0, setPreviewUrl0] = useState<string | null>(null);
    const [previewUrl1, setPreviewUrl1] = useState<string | null>(null);
    const [previewUrl2, setPreviewUrl2] = useState<string | null>(null);

    const [displayUrl0, setDisplayUrl0] = useState<string | null>(null);
    const [displayUrl1, setDisplayUrl1] = useState<string | null>(null);
    const [displayUrl2, setDisplayUrl2] = useState<string | null>(null);

    const [imageInfo0, setImageInfo0] = useState<ImageInfo | null>(null);
    const [imageInfo1, setImageInfo1] = useState<ImageInfo | null>(null);
    const [imageInfo2, setImageInfo2] = useState<ImageInfo | null>(null);

    useEffect(() => {
        setDisplayUrl0(null)
        setDisplayUrl1(null)
        setDisplayUrl2(null)

        const id = setInterval(() => {
            setDisplayUrl0(null)
            setDisplayUrl1(null)
            setDisplayUrl2(null)

            setTimeout(() => {
                setDisplayUrl0(previewUrl0)
                setDisplayUrl1(previewUrl1)
                setDisplayUrl2(previewUrl2)
            }, 50)
        }, extension == "longer_image" ? 25000 : 15000);

        setTimeout(() => {
            setDisplayUrl0(previewUrl0)
            setDisplayUrl1(previewUrl1)
            setDisplayUrl2(previewUrl2)
        }, 50)

        return () => clearInterval(id);
    }, [previewUrl0, previewUrl1, previewUrl2, extension]);

    return (<>
        <Box position={'absolute'} border={'solid'} borderColor={theme.colors.yellow[300]} top={`${pillarBox}px`}
             left={`${letterBox}px`}
             right={`calc(353px + ${letterBox}px)`} height={`calc(100vh - ${pillarBox * 2}px)`}
             pointerEvents={previewUrl0 ? 'auto' : 'none'}
        >
            {previewUrl0 &&
                <DraggableImage index={1} extension={extension} previewUrl={displayUrl0} viewWidth={viewWidth}
                                viewHeight={viewHeight}
                                setImageInfo={setImageInfo0}/>}
            {(previewUrl1 && (extension == "multiple2_image" || extension == "multiple3_image")) &&
                <DraggableImage index={2} extension={extension} previewUrl={displayUrl1} viewWidth={viewWidth}
                                viewHeight={viewHeight}
                                setImageInfo={setImageInfo1}/>}
            {(previewUrl2 && extension == "multiple3_image") &&
                <DraggableImage index={3} extension={extension} previewUrl={displayUrl2} viewWidth={viewWidth}
                                viewHeight={viewHeight}
                                setImageInfo={setImageInfo2}/>}
        </Box>
        <Box position={'absolute'} top={'0px'} left={'calc(100vw - 353px)'} padding={'8px'} width={'353px'}
             height={'100vh'} style={{background: 'var(--chakra-colors-chakra-body-bg)'}} zIndex={1}>
            <SendingUIBox extension={extension} setExtension={setExtension} imageInfo0={imageInfo0} imageInfo1={imageInfo1} imageInfo2={imageInfo2} setImageInfo0={setImageInfo0} setImageInfo1={setImageInfo1} setImageInfo2={setImageInfo2} setPreviewUrl0={setPreviewUrl0} setPreviewUrl1={setPreviewUrl1} setPreviewUrl2={setPreviewUrl2}></SendingUIBox>
        </Box>
    </>)
}