import {
    Box,
    Button,
    Divider,
    HStack,
    Image,
    Link,
    Select,
    Text,
    Tooltip,
    useDisclosure,
    useTheme
} from "@chakra-ui/react";
import * as React from "react";
import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {getAPIServer} from "./Configure";
import {ImageInfo} from "./ImageInfo";

export default function SendingUIBox({
                                         extension,
                                         setExtension,
                                         imageInfo0,
                                         setImageInfo0,
                                         imageInfo1,
                                         setImageInfo1,
                                         imageInfo2,
                                         setImageInfo2,
                                         setPreviewUrl0,
                                         setPreviewUrl1,
                                         setPreviewUrl2
                                     }: {
    extension: string,
    setExtension: Dispatch<SetStateAction<string>>,
    imageInfo0: ImageInfo | null,
    imageInfo1: ImageInfo | null,
    imageInfo2: ImageInfo | null,
    setImageInfo0: Dispatch<SetStateAction<ImageInfo | null>>,
    setImageInfo1: Dispatch<SetStateAction<ImageInfo | null>>,
    setImageInfo2: Dispatch<SetStateAction<ImageInfo | null>>,
    setPreviewUrl0: React.Dispatch<React.SetStateAction<string | null>> | null,
    setPreviewUrl1: React.Dispatch<React.SetStateAction<string | null>> | null,
    setPreviewUrl2: React.Dispatch<React.SetStateAction<string | null>> | null,
}) {
    const theme = useTheme();

    const [image0, setImage0] = useState<File | null>(null);
    const [image1, setImage1] = useState<File | null>(null);
    const [image2, setImage2] = useState<File | null>(null);

    const [selfImageURL0, setSelfImageURL0] = useState<string | null>(null);
    const [selfImageURL1, setSelfImageURL1] = useState<string | null>(null);
    const [selfImageURL2, setSelfImageURL2] = useState<string | null>(null);

    let selectedImageInx = 0;

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (selectedImageInx == 0) {
                setImage0(file);
            }

            if (selectedImageInx == 1) {
                setImage1(file);
            }

            if (selectedImageInx == 2) {
                setImage2(file);
            }

            if (setPreviewUrl0 == null) {
                if (selectedImageInx == 0) {
                    setImageInfo0({
                        "x": 100,
                        "y": 100,
                        "size": extension == "bigger_image" ? 900 : 500,
                        "fit": true,
                        "rotate": 0
                    })
                } else if (selectedImageInx == 1) {
                    setImageInfo1({
                        "x": 600,
                        "y": 100,
                        "size": 500,
                        "fit": true,
                        "rotate": 0
                    })
                } else if (selectedImageInx == 2) {
                    setImageInfo2({
                        "x": 100,
                        "y": 600,
                        "size": 500,
                        "fit": true,
                        "rotate": 0
                    })
                }
            }

            // 파일을 읽어와서 미리보기 URL 생성
            const reader = new FileReader();
            reader.onloadend = () => {
                if (selectedImageInx == 0) {
                    if (setPreviewUrl0 != null) {
                        setPreviewUrl0(reader.result as string);
                    } else {
                        setSelfImageURL0(reader.result as string);
                    }
                }

                if (selectedImageInx == 1) {
                    if (setPreviewUrl1 != null) {
                        setPreviewUrl1(reader.result as string);
                    } else {
                        setSelfImageURL1(reader.result as string);
                    }
                }

                if (selectedImageInx == 2) {
                    if (setPreviewUrl2 != null) {
                        setPreviewUrl2(reader.result as string);
                    } else {
                        setSelfImageURL2(reader.result as string);
                    }
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        setImageInfo0({
            "x": 100,
            "y": 100,
            "size": extension == "bigger_image" ? 900 : 500,
            "fit": true,
            "rotate": 0
        })
    }, [extension]);

    const [uploadResponse, setUploadResponse] = useState("");
    const [isInUpload, setIsInUpload] = useState(false);
    let blockUpload = false;

    const copyTooltipProps = useDisclosure()

    const handleUpload = async () => {
        if (!image0) {
            alert("이미지를 고르지 않고 업로드할 수 없습니다.");
            return;
        }
        if (!image1 && (extension == "multiple2_image" || extension == "multiple3_image")) {
            alert("이미지를 2개 다 선택해야 합니다 - 필요하지 않은경우라면 옵션을 조절해주세요.")
            return
        }
        if (!image2 && extension == "multiple3_image") {
            alert("이미지를 3개 다 선택해야 합니다 - 필요하지 않은경우라면 옵션을 조절해주세요.")
            return
        }

        // state는 조금 늦게 바뀌기 때문에, 바뀌기도 전에 두번 연타하는걸 방지
        if (blockUpload) return;
        blockUpload = true;
        setIsInUpload(true);

        const formData = new FormData();
        formData.append('files', image0);

        try {
            let image_infos = []
            image_infos.push(imageInfo0)
            if (extension == "multiple2_image") {
                image_infos.push(imageInfo1)
                formData.append('files', image1!!)
            } else if (extension == "multiple3_image") {
                image_infos.push(imageInfo1)
                image_infos.push(imageInfo2)

                formData.append('files', image1!!)
                formData.append('files', image2!!)
            }
            let image_infos_base = btoa(JSON.stringify(image_infos))
            const response = await fetch(getAPIServer() + `image?extension=${extension}&image_infos=${image_infos_base}`, {
                method: 'POST',
                cache: 'no-cache',
                body: formData,
            });

            if (response.status == 200) {
                const result = await response.json();
                setUploadResponse(`#i${result.uid}`)
            } else {
                setUploadResponse("업로드에 실패했습니다. 서버가 바쁘거나, 단기간에 요청을 너무 많이 보냈거나, 이미지가 손상된 이미지일 수 있습니다, 다시 시도해보세요!")
            }
        } catch (error) {
            setUploadResponse("업로드에 실패했습니다. 서버가 바쁘거나, 단기간에 요청을 너무 많이 보냈거나, 이미지가 손상된 이미지일 수 있습니다, 다시 시도해보세요!")
        } finally {
            setIsInUpload(false);
            blockUpload = false;
        }
    };

    return (
        <>
            <HStack>
                <Text fontSize={'xl'}>룽샷</Text>
                <Text fontSize={'xs'} marginTop={'1em'}>Beta</Text>
            </HStack>
            <Text>룽샷은 현재 베타 버전입니다. 이미지가 의도한 위치, 각도, 크기와 약간 다르게 표시될 수 있습니다. 탄빵 여러분의 양해를 부탁드립니다.</Text>
            <Divider marginTop={'8px'} marginBottom={'8px'}/>
            <Image src={"static/guide_wide_view.png"}></Image>
            <Text>플레이어의 단축메뉴에서 넓게 보기(T) 를 활성화 해주세요.<br/><b>노란색 격자</b>가 방송 화면과 일치하도록 해야 이미지가 원하는 느낌이랑 비슷하게 표시됩니다.</Text>
            <Divider marginTop={'8px'} marginBottom={'8px'}></Divider>
            <input type="file" accept="image/*" ref={fileInputRef} style={{display: 'none'}}
                   onChange={handleImageChange}/>
            <Select marginBottom={'8px'} defaultValue={'standard'}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setExtension(e.target.value ?? "standard")
                    }}>
                <option value={'standard'}>일반</option>
                <option value={'bigger_image'}>더 크게 - 2000치즈</option>
                <option value={'longer_image'}>더 길게 (25초) - 2000치즈</option>
                <option value={'multiple2_image'}>이미지 2장 - 2000치즈</option>
                <option value={'multiple3_image'}>이미지 3장 - 3000치즈</option>
            </Select>
            {selfImageURL0 &&
                <Image width={'100vw'} height={'auto'} src={selfImageURL0} alt={"Uploaded Image 1"}></Image>}
            <Button onClick={() => {
                selectedImageInx = 0;
                handleButtonClick();
            }}
                    marginBottom={'8px'}>{(extension == "multiple2_image" || extension == "multiple3_image") && "1번째 "}이미지
                선택하기 {image0 != null ? " (선택됨)" : ""}</Button>
            {(extension == "multiple2_image" || extension == "multiple3_image") && <>
                {selfImageURL1 &&
                    <Image width={'100vw'} height={'auto'} src={selfImageURL1} alt={"Uploaded Image 2"}></Image>}
                <Button onClick={() => {
                    selectedImageInx = 1;
                    handleButtonClick();
                }} marginBottom={'8px'}>2번째 이미지 선택하기 {image1 != null ? " (선택됨)" : ""}</Button></>}
            {extension == "multiple3_image" && <>
                {selfImageURL2 &&
                    <Image width={'100vw'} height={'auto'} src={selfImageURL2} alt={"Uploaded Image 3"}></Image>}
                <Button onClick={() => {
                    selectedImageInx = 2;
                    handleButtonClick();
                }} marginBottom={'8px'}>3번째 이미지 선택하기 {image2 != null ? " (선택됨)" : ""}</Button></>}
            <Divider marginBottom={'8px'}/>
            <Divider marginTop={'8px'} marginBottom={'8px'}/>
            <Button isLoading={isInUpload} loadingText={'업로드 중...'} onClick={handleUpload}>이 상태로 업로드 하기</Button>
            <Divider marginTop={'8px'} marginBottom={'8px'}/>
            원활한 시청환경을 위해, <b style={{color: `${theme.colors.red[400]}`}}>방송의 주요 컨텐츠나 빵룽을</b> 의미없이 가려 방송을 방해하거나 흐름을 끊지
            않도록 주의해 주세요.
            <Divider marginTop={'8px'} marginBottom={'8px'}/>
            <Text>{uploadResponse.startsWith("#") ? <>업로드가 완료되었습니다. 빵룽님 방송에<br/>
                <Tooltip label={"복사 완료!"} openDelay={999999999} closeDelay={1000} {...copyTooltipProps}>
                    <Link href={"#/"} fontSize={setPreviewUrl0 == null ? '4em' : '1em'} onClick={async () => {
                        try {
                            await navigator.clipboard.writeText(`(${uploadResponse})`);
                            copyTooltipProps.onOpen()
                        } catch {
                            prompt('자동설정에 실패했습니다, 수동으로 복사할 수 있습니다.', `(${uploadResponse})`)
                        }
                    }}>{`(${uploadResponse})`}</Link>
                </Tooltip>
                <br/> 가 포함된 치즈 후원을 보내면 방송에 이미지가 표시됩니다.<br/>코드를 클릭하여 복사할 수 있습니다.</> : uploadResponse}</Text>
            <Box minHeight={'2em'} >
            </Box>
            <Box position={'absolute'} bottom={'8px'}>
                이 서비스는 치지직과 <b style={{color: `${theme.colors.red[400]}`}}>관련이 없는 서드파티</b> 서비스입니다.
            </Box>
        </>
    )
}