import {Box, Divider, useTheme} from "@chakra-ui/react";
import {useState} from "react";
import {ImageInfo} from "./ImageInfo";
import SendingUIBox from "./SendingUIBox";
import MobileSorryModal from "./MobileSorryModal";

export default function MobileSendingBox() {

    const theme = useTheme();
    const [extension, setExtension] = useState("standard")
    const [imageInfo0, setImageInfo0] = useState<ImageInfo | null>(null);
    const [imageInfo1, setImageInfo1] = useState<ImageInfo | null>(null);
    const [imageInfo2, setImageInfo2] = useState<ImageInfo | null>(null);

    return (
        <Box minWidth={'100vw'} minHeight={'100vh'}>
            <MobileSorryModal></MobileSorryModal>
            <SendingUIBox extension={extension} setExtension={setExtension} imageInfo0={imageInfo0} imageInfo1={imageInfo1} imageInfo2={imageInfo2} setImageInfo0={setImageInfo0} setImageInfo1={setImageInfo1} setImageInfo2={setImageInfo2} setPreviewUrl0={null} setPreviewUrl1={null} setPreviewUrl2={null} />
        </Box>
    )
}