import * as React from "react"
import {useEffect, useState} from "react"
import {Box, ChakraProvider, theme,} from "@chakra-ui/react"
import OverlayBox from "./OverlayBox";
import MobileSendingBox from "./MobileSendingBox";

export default function App() {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        // 뷰포트 크기가 변경될 때 호출되는 핸들러 함수입니다.
        const handleResize = () => {
            if (window.innerWidth > window.innerHeight) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        };

        // 'resize' 이벤트에 핸들러를 추가합니다.
        window.addEventListener('resize', handleResize);
        handleResize()

        // 컴포넌트가 unmount 될 때 이벤트 리스너를 제거합니다.
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ChakraProvider theme={theme}>
            {isMobile ? <> <Box overflow={'hidden'} width={'100vw'} height={'100vh'}>
                <iframe width={'100%'} height={'100%'} frameBorder={'1'}
                        src={'https://chzzk.naver.com/live/a3c5623862d0d1dda383f5efbc39c6f4'}></iframe>
            </Box>
                <OverlayBox/></> : <MobileSendingBox/>}
        </ChakraProvider>
    )
}