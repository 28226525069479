import {Box, Checkbox, Image, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useState} from "react";
import Draggable from "react-draggable";
import {ImageInfo} from "./ImageInfo";

export default function DraggableImage({index, previewUrl, viewWidth, viewHeight, extension, setImageInfo}: {
    index: number,
    previewUrl: string | null,
    viewWidth: number,
    viewHeight: number,
    extension: string,
    setImageInfo: React.Dispatch<React.SetStateAction<ImageInfo | null>>
}) {
    const [imageSize, setImageSize] = useState(500);
    const [imageFit, setImageFit] = useState(true);
    const [imageRotate, setImageRotate] = useState(0);

    const [deltaPosition, setDeltaPosition] = useState({'x': 0, 'y': 0})
    const [finalPosition, setFinalPosition] = useState({'x': 0, 'y': 0})

    const handleDrag = (e: any, ui: any) => {
        let {x, y} = deltaPosition;
        x += ui.deltaX
        y += ui.deltaY
        setDeltaPosition({'x': x, 'y': y})
        setFinalPosition({'x': (x / viewWidth) * 1920, 'y': (y / viewHeight) * 1080})
        updateImageInfo()
    };

    const updateImageInfo = () => {
        let info = {
            "x": finalPosition["x"],
            "y": finalPosition["y"],
            "size": imageSize,
            "fit": imageFit,
            "rotate": imageRotate
        }
        setImageInfo(info)
    }

    useEffect(() => {
        if (extension != "bigger_image" && imageSize > 500) {
            setImageSize(500)
        }
        updateImageInfo()
    }, [extension, previewUrl])

    return (<Draggable onDrag={handleDrag}>
        <Box position={'absolute'}>
            <Box position={'absolute'} style={{
                zIndex: 1,
                padding: "12px",
                backgroundColor: "#00000099",
                width: `250px`,
                height: `auto`,
            }}>
                <Text>{(extension == "multiple2_image" || extension == "multiple3_image") && `${index}번째 `}이미지 크기</Text>
                <Slider aria-label='slider-ex-1' min={50} max={extension == "bigger_image" ? 900 : 500}
                        onChange={(val) => {
                            setImageSize(val)
                            updateImageInfo()
                        }} defaultValue={imageSize}>
                    <SliderTrack>
                        <SliderFilledTrack/>
                    </SliderTrack>
                    <SliderThumb/>
                </Slider>
                <Text>회전</Text>
                <Slider aria-label='slider-ex-1' min={0} max={360} onChange={(val) => {
                    setImageRotate(val)
                    updateImageInfo()
                }} defaultValue={imageRotate}>
                    <SliderTrack>
                        <SliderFilledTrack/>
                    </SliderTrack>
                    <SliderThumb/>
                </Slider>
                <Text marginBottom={'8px'}>비율 맞추기</Text>
                <Checkbox defaultChecked={imageFit} onChange={(e) => {
                    setImageFit(e.target.checked)
                    updateImageInfo()
                }}></Checkbox>
            </Box>
            <Image src={previewUrl != null ? previewUrl : ""} alt="Preview"
                   style={{
                       width: `${imageSize / 1920 * viewWidth}px`,
                       height: `${imageSize / 1080 * viewHeight}px`,
                       transform: `rotate(${imageRotate}deg)`
                   }}
                   objectFit={imageFit ? 'contain' : 'fill'}
                   onDragStart={(event: React.DragEvent<HTMLImageElement>) => {
                       event.preventDefault()
                   }}/>
        </Box>
    </Draggable>)
}